import { ErrorHandler, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { routes } from './app.routes';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { en_US, NZ_I18N } from 'ng-zorro-antd/i18n';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';
import { ErrorInterceptor } from './core/interceptors/error.interceptor';
import { AcceptInvitationComponent } from './accept-invitation/accept-invitation.component';
import { SharedModule } from './shared/shared.module';
import { NavigationService } from './shared/services/navigation.service';
import { AuthService } from './shared/services/auth.service';
import { KorviuLoaderComponent } from './shared/components/korviu-loader/korviu-loader.component';
import { LangInterceptor } from './core/interceptors/lang.interceptor';
import { GlobalErrorHandler } from './core/handler/global-error-handler.handler';
import { ModalService } from './shared/services/modal.service';

@NgModule({
	declarations: [AppComponent, AcceptInvitationComponent],
	imports: [
		CommonModule,
		BrowserModule,
		BrowserAnimationsModule,
		HttpClientModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient]
			}
		}),
		RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
		SharedModule,
		KorviuLoaderComponent
	],
	providers: [
		{
			provide: ErrorHandler,
			useClass: GlobalErrorHandler
		},
		TranslateService,
		{ provide: NZ_I18N, useValue: en_US },
		{ provide: HTTP_INTERCEPTORS, useClass: LangInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
		ModalService,
		NavigationService,
		AuthService
	],
	bootstrap: [AppComponent]
})
export class AppModule {}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
	return new TranslateHttpLoader(http);
}
